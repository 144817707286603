import React, { useRef } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import ListGroup from 'react-bootstrap/ListGroup'
import Button from 'react-bootstrap/Button'

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faCoffee } from '@fortawesome/free-solid-svg-icons';
// import LogoAceim from 'images/logo_aceim.png';
// import LogoEIM from 'images/logo_eim.png';
// import LogoEIMBlue from 'images/logo_eim_blue.png';
// import LogoEIMWhite from 'images/logo_eim_white.png';


// OTRA MANERA DE CONDICIONAL
// function SetGoldenStarFinished(props) {
// 	const finishedVal = props.finished;
// 	// const lastLevelName = props.last_level_name;

// 	if (finishedVal) {
// 		return <span className='float-right'><i className="fa fa-star mr-2 text-warning"></i>¡Finalizado!</span>;
// 	} else {
// 		return <span className='badge badge-dark float-right'> {props.lastLevelName}</span>;
// 	}
	
// }


// class CareersResume extends React.Component {
// 	render() {
// 		return (

// 			  <ListGroup.Item action variant="info">
// 			    {this.props.name}
// 				< SetGoldenStarFinished finished={this.props.finished} lastLevelName={this.props.last_level_name} />
// 				<ProgressBar animated variant="warning" now={this.props.percent} label={`${this.props.percent}%`} />
// 			  </ListGroup.Item>
			
// 		);
// 	}
// }

class LanguageItem extends React.Component {
	render() {
		return (

			<ListGroup.Item action variant="light">
				<p className='text-muted'>{this.props.name} a {this.props.total_levels} niveles</p>

				{this.props.enabled_enroll &&
					<Button className='float-right btn-lg btn-primary' href='#'>¡Empezar Ahora!</Button>
				}
			</ListGroup.Item>

		);
	}
}


export default LanguageItem
