import React, { useRef } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import FormLogin from './FormLogin'
import Button from 'react-bootstrap/Button';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faCoffee } from '@fortawesome/free-solid-svg-icons';
// import LogoAceim from 'images/logo_aceim.png';
// import LogoEIM from 'images/logo_eim.png';
// import LogoEIMBlue from 'images/logo_eim_blue.png';
// import LogoEIMWhite from 'images/logo_eim_white.png';


// if (outSimulation == true) {
// 	console.log('Sessión Cerrada');
// 	sessionStorage.setItem('simulated_user_id', null);
// }

// function fallback_url() {
// 	let aux;
// 	aux = sessionStorage.getItem('student');
// 	if (aux == null) {aux = sessionStorage.getItem('instructor')};

// 	return aux
// }


class NavBarMainSimulated extends React.Component {
	render() {
		return (
			<Navbar expand="lg" className='bg-danger text-white' fixed='top'>
				<Navbar.Brand href='https://fundeim.com' className='nav-bar-link text-info'>Fundeim</Navbar.Brand>
				<Navbar.Toggle aria-controls="basic-navbar-nav" />
				<Navbar.Collapse id="basic-navbar-nav">

					<Nav>
						<Nav.Link href={this.props.home_url} className='nav-bar-link text-muted'>
							<span><i className="fa fa-home mr-2"></i></span>
							Inicio
							{/*<FontAwesomeIcon icon={faCoffee} />*/}
						</Nav.Link>
					</Nav>
					<a id='userName' className='text-light mr-2'></a>
					<a className="login-button btn btn-sm btn-outline-secondary" rel="nofollow" data-method="delete" href={this.props.exit_url}>Salir</a>
				</Navbar.Collapse>
			</Navbar>

		);
	}
}

export default NavBarMainSimulated
