import React, { useRef } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import FormLogin from './FormLogin'
import Button from 'react-bootstrap/Button';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import axios from 'axios-on-rails'
import LoginButton from './LoginButton';
import LogoutButton from './LogoutButton';
import LogoFundeim from 'images/logo_fundeim_white.svg';
import Image from 'react-bootstrap/Image'


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
// import LogoAceim from 'images/logo_aceim.png';
// import LogoEIM from 'images/logo_eim.png';
// import LogoEIMBlue from 'images/logo_eim_blue.png';
// import LogoEIMWhite from 'images/logo_eim_white.png';

const handleLinkClick = event => {

	// 👇️ refers to the link element
	// console.log(event.currentTarget);

	navigator.clipboard.writeText('fundeimucv@gmail.com');
	toastr.success('¡Dirección de correo copiada en el portapapeles!');
};

class NavBarMain extends React.Component {

	checkLoggin(){
		var loggedInUser;
		axios.get('/home/longged_in', { withCredentials: true }).then((response) => {
			
			loggedInUser = response.data
		}).catch(error => {
			console.log("Error: ", error)
		})
		return loggedInUser
	}

	render() {
		let loginButton;
		console.log(this.checkLoggin());

		if (false) {
			loginButton = <LogoutButton />;
		} else {
			loginButton = <LoginButton />;
		}

		return (
			<Navbar expand="lg" className='color-bg-nav' fixed='top'>
				<Navbar.Brand href="https://fundeim.com" className='nav-bar-link'>
					<Image src={LogoFundeim} style={{ width: 30 + '%' }} />
				</Navbar.Brand>
				<Navbar.Toggle aria-controls="basic-navbar-nav" />
				<Navbar.Collapse id="basic-navbar-nav">
					<Nav className="mr-auto">
						<Nav.Link href="#" className='nav-bar-link text-muted' onClick={handleLinkClick}>
							<FontAwesomeIcon icon={faEnvelope} />
							 Contáctanos
						</Nav.Link>
					</Nav>
				</Navbar.Collapse>
			</Navbar>
		);
	}
}

export default NavBarMain
