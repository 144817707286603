import React, { useRef } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import ProgressBar from 'react-bootstrap/ProgressBar'
import ListGroup from 'react-bootstrap/ListGroup'
import ButtonEnrollConfirmation from '../users_session/ButtonEnrollConfirmation'
import CustomButton from '../users_session/CustomButton'

class CareerResume extends React.Component {

	render() {
		return (
			<ListGroup.Item variant="info">
				<p className='text-muted'>{this.props.career_resume.name}</p>
				{this.props.enroll.enabled &&
					<ButtonEnrollConfirmation enroll={this.props.enroll}/>
				}
			</ListGroup.Item>

		);
	}
}


export default CareerResume
