// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
global.toastr = require("toastr")

require("@rails/ujs").start();
require("turbolinks").start();
require("@rails/activestorage").start();
require("channels");
require("trix");
require("@rails/actiontext");

import "jquery"
import "bootstrap";
import 'intl-tel-input/build/css/intlTelInput';
import "../stylesheets/application"


import intlTelInput from 'intl-tel-input';

document.addEventListener("turbolinks:load", () => {

	// $('.summitComment').on('click', function(event) {

	// 	let xhr = new XMLHttpRequest();
	// 	let formId = $(this).attr('formId');
	// 	let responseDivId = $(this).attr('resultDiv');
	// 	var form = document.getElementById(formId);
	// 	var formData = new FormData(form);

	// 	event.preventDefault();
	// 	event.stopPropagation();

	// 	xhr.open("POST", form.action);
	// 	xhr.onload = function(event) {
	// 		if (event.currentTarget.status == 200) {
	// 			const div = document.createElement('div');
	// 			div.innerHTML = event.currentTarget.response;

	// 			let responseField = document.getElementById(responseDivId);
	// 			responseField.prepend(div);
	// 			toastr.success('¡Comentario Guardado!')
	// 			form.elements['comment_message'].value = '';
	// 		} else {
	// 			let resp = JSON.parse(event.target.response);
	// 			toastr.error('Error en el comentario, por favor verifique e inténtelo nuevamente: ' + resp.errors);
	// 		}
	// 	};
	// 	xhr.send(formData);
	// });


	var input = document.querySelector("#phone"),
		fullNumberPhone = document.querySelector("#fullNumberPhone"),
		sourceCoutry = document.querySelector('#student_source_country');

	console.log(fullNumberPhone);
	var handleChange = function() {
		// input.value = iti.getSelectedCountryData()['dialCode']+input.textContent

		// output.textContent = iti.getSelectedCountryData();
		console.log(iti.getSelectedCountryData());

		fullNumberPhone.value = "+" + iti.getSelectedCountryData()['dialCode'] + "-" + input.value;
		sourceCoutry.value = iti.getSelectedCountryData()['name']

	};

	if (fullNumberPhone != null) {
		var iti = intlTelInput(input, {
			nationalMode: true,
			preferredCountries: ["ve"],
			separateDialCode: true,
  			initialCountry: "ve",
			geoIpLookup: function(callback) {
				$.get('https://ipinfo.io', function() { }, "jsonp").always(function(resp) {
					var countryCode = (resp && resp.country) ? resp.country : "us";
					callback(countryCode);
				});
			}
		});


		// listen to "keyup", but also "change" to update when the user selects a country
		input.addEventListener('change', handleChange);
		input.addEventListener('keyup', handleChange);
	}





	$('[data-toggle="tooltip"]').tooltip({ trigger: "hover" });
	$('[data-toggle="popover"]').popover();
	$("#updatePersonalData").modal({ keyboard: false, backdrop: 'static' });
	$("#AlertPaymentUnread").modal({ keyboard: false, backdrop: 'static' });

	$(".ownEmail").on('click', function() {
		navigator.clipboard.writeText('fundeimucv@gmail.com');
		toastr.success('¡Dirección de correo copiada en el portapapeles!')
	});


	$(".diplayModalBtn").on('click', function() {
		let idModal = $(this).attr('idModal');
		$(`#${idModal}`).modal();
	});

	$('.onlyOneCharacter').on('input', function(evt) {
		var node = $(this);
	});

	$('.upcase').on('input', function(evt) {
		var node = $(this);
		node.val(node.val().toUpperCase());
	});

	$('.onlyNumbers').on('input', function(evt) {
		var node = $(this);
		node.val(node.val().replace(/[^0-9]/g, ''));
	});

	$('.confirmBtn').on('click', function() {
		let ele = $(this);
		let url = ele.attr('url');
		let descUser = ele.attr('desc_user');
		let descInscrip = ele.attr('desc_inscrip');
		let textConfirm = `<b>¿Confirma que deseas completar la preinscripcion de ${descUser} en ${descInscrip} y de que acepta la normativa expuesta?</b></br>`
		$('#confirmDialog #content #titleConfirmation').html(textConfirm);
		$('#confirmDialog #confirmLink').attr('href', url);
		$('#confirmDialog').modal();
	})

})


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
// Support component names relative to this directory:
var componentRequireContext = require.context("components", true);
var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);
