import React, { useRef } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button'

class ButtonEnrollConfirmation extends React.Component {

	constructor(props) {
		super(props);
		this.handleClick = this.handleClick.bind(this);
	}


	handleClick(id, e) {
		e.preventDefault();
		let ele = $(`#${id}`);
		let url = ele.attr('url');
		let descUser = ele.attr('desc_user');
		let descInscrip = ele.attr('desc_inscrip');

		let textConfirm = `<b>¿Confirma que deseas completar la preinscripcion de ${descUser} en ${descInscrip} y de que acepta la normativa expuesta?</b></br>`

		$('#confirmDialog #content #titleConfirmation').html(textConfirm);

		$('#confirmDialog #confirmLink').attr('href', url);
		$('#confirmDialog').modal();

	}


	render() {
		return (

			<h6 className='my-3'>
				<Button href='#' className="btn btn-success tooltip-btn confirmBtn" data-toggle='tooltip' title={this.props.enroll.tooltip_desc} url={this.props.enroll.url} desc_user={this.props.enroll.user_desc} desc_inscrip={this.props.enroll.desc} id={this.props.enroll.id} onClick={(e) => this.handleClick(this.props.enroll.id, e)}>{this.props.enroll.title} </Button>
			</h6>
		);
	}
}

export default ButtonEnrollConfirmation
