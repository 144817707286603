import React, {useRef} from 'react'
import LogoFundeim from 'images/logo_fundeim.svg';
import Image from 'react-bootstrap/Image'

class LoginTitle extends React.Component {
  render(content) {
    return (
		  <div>
        <a className='logo-name' href='https://fundeim.com/'>
          <Image src={LogoFundeim} style={{ width: 35 + '%' }} />
        </a>
        {content}
      </div>
    );
  }
}
export default LoginTitle