import React, { useRef } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faCoffee } from '@fortawesome/free-solid-svg-icons';
// import LogoAceim from 'images/logo_aceim.png';
// import LogoEIM from 'images/logo_eim.png';
// import LogoEIMBlue from 'images/logo_eim_blue.png';
// import LogoEIMWhite from 'images/logo_eim_white.png';


// {name: @user.name, profile_image: @user.profile_image, email: @user.email, full_name: @user.full_name, ci: @user.student.ci}

// IMPORTANTE PARA LAS IMAGENES: REVISAR (22102021)
// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

class PersonalCard extends React.Component {
	render() {
		return (
			<Card>
				<Card.Img variant="top" src={this.props.profile_image} />
				<Card.Body>
					<Card.Title>
						{this.props.name}
						<a href='#' className="float-right">
							<span className='fa fa-pencil'></span>
						</a>
					</Card.Title>
					<Card.Text>
						<b>{this.props.email}</b>
					</Card.Text>
					<Card.Text>
						{this.props.full_name}
					</Card.Text>
					<Card.Text className='text-muted'>
						CI: {this.props.ci}
					</Card.Text>
					

				</Card.Body>
			</Card>

		);
	}
}

export default PersonalCard
