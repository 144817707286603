import React, { useRef } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import FormLogin from './FormLogin'
import Button from 'react-bootstrap/Button';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import LogoFundeim from 'images/logo_fundeim_white.svg';
import Image from 'react-bootstrap/Image'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
// import LogoAceim from 'images/logo_aceim.png';
// import LogoEIM from 'images/logo_eim.png';
// import LogoEIMBlue from 'images/logo_eim_blue.png';
// import LogoEIMWhite from 'images/logo_eim_white.png';


// if (outSimulation == true) {
// 	console.log('Sessión Cerrada');
// 	sessionStorage.setItem('simulated_user_id', null);
// }

// function fallback_url() {
// 	let aux;
// 	aux = sessionStorage.getItem('student');
// 	if (aux == null) {aux = sessionStorage.getItem('instructor')};

// 	return aux
// }

const handleLinkClick = event => {

	// 👇️ refers to the link element
	// console.log(event.currentTarget);

	navigator.clipboard.writeText('fundeimucv@gmail.com');
	toastr.success('¡Dirección de correo copiada en el portapapeles!');
};


class NavBarMainLogin extends React.Component {
	render() {
		return (
			<Navbar expand="lg" className={this.props.color_bar} fixed='top'>
				<Navbar.Brand href='https://fundeim.com' className='nav-bar-link'>
					<Image src={LogoFundeim} style={{ width: 70 + 'px' }} />
				</Navbar.Brand>
				<Navbar.Toggle aria-controls="basic-navbar-nav" />
				<Navbar.Collapse id="basic-navbar-nav">

					<Nav>
						<Nav.Link href={this.props.home_url} className='nav-bar-link text-muted'>
							<span><i className="fa fa-home mr-2"></i></span>
							Inicio
							{/*<FontAwesomeIcon icon={faCoffee} />*/}
						</Nav.Link>
					</Nav>
					<Nav className="mr-auto">
						<Nav.Link href="#" className='nav-bar-link text-muted' onClick={handleLinkClick}>
							<span><i className="fa fa-envelope mr-2"></i></span>
							Contáctanos
						</Nav.Link>
					</Nav>
					<a id='userName' className='text-light mr-2' href={this.props.edit_user}></a>
					<a className='text-light mr-2 tooltip-btn' title='Editar Datos Personales' placement='left' href={this.props.edit_user}  >
						<span><i className="fa fa-pencil mr-2"></i></span>
					</a>

					<a className="login-button btn btn-sm btn-outline-secondary tooltip-btn" title='Cerrar Sessión' placement='left' rel="nofollow" data-method={this.props.data_method} href={this.props.exit_url}>Salir</a>
				</Navbar.Collapse>
			</Navbar>

		);
	}
}

export default NavBarMainLogin
