import React, { useRef } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

import CustomButton from '../users_session/CustomButton'

class ButtonSectionShow extends React.Component {
	render() {
		return (
			<Button href={props.href} className={props.classes} data-toggle='tooltip' title={props.tooltipTitle}>
				{props.name}
			</Button>
		);
	}
}

export default ButtonSectionShow
