import React, { useRef } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import ProgressBar from 'react-bootstrap/ProgressBar'
import ListGroup from 'react-bootstrap/ListGroup'
import Button from 'react-bootstrap/Button'
import ButtonEnrollConfirmation from '../users_session/ButtonEnrollConfirmation'
import Table from 'react-bootstrap/Table'
// import CustomButton from '../users_session/CareersResume'

class StudentCareersList extends React.Component {

	columns = [
		{
			title: "Nombre",
			dataIndex: "title",
			key: "title",
		},
		{
			title: "Activo",
			dataIndex: "enabled",
			key: "enabled",
		},
		{
			title: "Url",
			dataIndex: "url",
			key: "url",
		},
	];

	state = {
		careers: [],
	};

	componentDidMount() {
		// this.setState()
		this.loadOptionCareer();
	}

	loadOptionCareer = () => {
		// const url = "api/v1/careers/14993/resumen.json?period_id=61";
		const url = "api/v1/careers.json?id=17443&period_id=61";
		fetch(url)
			.then((data) => {
				if (data.ok) {
					return data.json();
				}
				throw new Error("Network error.");
			})
			.then((data) => {
				data.forEach((career) => {
					const newEl = {
						key: career.id,
						id: career.id,
						enabled: career.enabled,
						title: career.title,
						url: career.url,
						tooltip_desc: career.tooltip_desc,
						user_desc: career.user_desc,
					};

					this.setState(prevState => ({
						careers: [...prevState.careers, newEl],
					}));

				});
			})
			.catch((err) => toastr.error("Error: " + err));
	};



	render() {
		console.log('Careers: ' + this.state.careers);
		return (

			<Button>{this.state.careers.last}</Button>

		);
	}
}


export default StudentCareersList
