import React, { useRef } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button'

class CustomButton extends React.Component {
	render() {
		return (
			<Button href={this.props.current_section.href} className={this.props.current_section.classes} data-toggle='tooltip' title={this.props.current_section.tooltipTitle}>
				<i className={this.props.current_section.iconsClass}></i>
				{this.props.current_section.name}
			</Button>
		);
	}
}

export default CustomButton
